import React, { useEffect, useState } from "react";
import { Card, Menu, Dropdown, Form, Row, Col, Select, Button, InputNumber,Modal, Spin, message, Table, Image } from "antd";
import { getDocumentType, getCustomerData } from "../../../services/generic";
import { getPendingSalesOrders, getRoleBusinessUnit } from "../../../services/custom";
import { Resizable } from "react-resizable";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import Axios from "axios";
import { v4 as uuid } from "uuid";
import { serverUrl, genericUrl } from "../../../constants/serverConfig";
import DownArrow from "../../../assets/images/downArrow.svg";

// import "antd/dist/antd.css";
// import "../../../styles/app.css";

const { Option } = Select;

const ResizableCell = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const PendingSalesOrder = () => {
  const [bunitData, setBunitData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [bUnitId, setBUnitId] = useState("");
  const [documentTypeId, setDocumentTypeId] = useState("");
  const [customerId, setCustomerId] = useState(null);

  const [dataSource, setDataSource] = useState([]);
  const [changedValue, setChangedValue] = useState("");

  const [loading, setLoading] = useState(false);
  const [headerform] = Form.useForm();
  const [customerData, setCustomerData] = useState([]);
  const [uniueOrderId, SetUniueOrderId] = useState([]);
  const [priviewData, setPriviewData] = useState([]);
  const [changedKey, setChangedKey] = useState("");
  const [changedIndex, setChangedIndex] = useState("");
  const [recordValue, setRecordValue] = useState([]);

  const [keys, setKeys] = useState([]);
  const [expand, setExpand] = useState(false);

  const getBusinessUnit = async () => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const businessUnitResponse = await getRoleBusinessUnit(userData.user_id);
    headerform.setFieldsValue({
      businessUnit: businessUnitResponse.bUnitName,
    });
    setBunitData(businessUnitResponse.userBunit);
    setBUnitId(businessUnitResponse.defaultCsBunitId);
  };
  const onSelectBusinessUnit = (e) => {
    setBUnitId(e);
  };

  const onSelectDocumentType = (e) => {
    setDocumentTypeId(e);
  };

  const onSelectCustomer = (e) => {
    if (e === undefined || e === null) {
      setCustomerId(null);
    } else {
      setCustomerId(e);
    }
  };

  const getDocument = async () => {
    const documentTypeResponce = await getDocumentType();
    setDocumentData(documentTypeResponce.searchData);
  };

  const getCustomer = async () => {
    const customerResponse = await getCustomerData();
    setCustomerData(customerResponse.searchData);
  };

  const columns = [
    { title: "SO Date", dataIndex: "SoDate", key: "SoDate", width: 130 },
    {
      title: "Sales Order",
      dataIndex: "SalesOrder",
      key: "SalesOrder",
      width: 130,
    },
    {
      title: "Customer",
      dataIndex: "Customer_name",
      key: "Customer",
      width: 130,
    },
    { title: "Scheduled Delivery Date", dataIndex: "ScheduledDeliveryDate", key: "ScheduledDeliveryDate", width: 80, ellipsis: true },
    { title: "Product", dataIndex: "product", key: "product", width: 80, ellipsis: true },
    { title: "Description", dataIndex: "Description", key: "Description", width: 80, ellipsis: true },
    { title: "Order Qty", dataIndex: "orderQty", key: "orderQty", width: 80, ellipsis: true },
    { title: "Res/Req Qty", dataIndex: "resReqQty", key: "res/ReqQty", width: 80, ellipsis: true },
    { title: "Stock Qty", dataIndex: "stockQty", key: "stockQty", width: 80, ellipsis: true },
    { title: "Design Status", dataIndex: "designStatus", key: "designStatus", width: 80, ellipsis: true },
    {
      title: "Req Qty",
      dataIndex: "reqQty",
      key: "reqQty",
      width: 80,
      ellipsis: true,
      render: (text, record, index) => (
        <InputNumber
          readOnly={record.customer ? false : true}
          size="small"
          style={{ width: "95%" }}
          min={0}
          max={1000000000}
          value={text}
          onChange={onInputChange("reqQty", index, record)}
        />
      ),
    },
    { title: "Sales Rep", dataIndex: "SalesRep", key: "SalesRep", width: 80, ellipsis: true },
    { title: "Image", dataIndex: "imageUrl", key: "imageUrl", width: 80, render: (text) => (text !== null ? <Image src={`${text === null ? null : `${text}.png`}`} /> : null) },
  ];
  const [column, setColumn] = useState(columns);

  const onInputChange = (key, index, record) => (value) => {
    // console.log(key, index, record, value);
    setRecordValue(record);

    setChangedValue(value);
    setChangedKey(key);
    setChangedIndex(index);
  };

  useEffect(() => {
    let newData = [...dataSource];
    if (newData.length > 0) {
      // console.log(changes);
      // console.log(recordValue);

      if (newData[changedIndex].key === recordValue.key) {
        newData[changedIndex][changedKey] = changedValue;
      }
      //  else {
      //   for (let index = 0; index < newData.length; index++) {
      //     for (let i = 0; i < newData[index].children.length; i++) {
      //       if (newData[index].children[i].key === recordValue.key) {
      //         newData[index].children[i][changedKey] = changedValue;
      //       }
      //     }
      //   }
      // }

      setDataSource(newData);
    }
  }, [changedValue]);

  const components = {
    header: {
      cell: ResizableCell,
    },
  };

  let finalColumns = column.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  // console.log("finalColumns======================>",finalColumns)
  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumn((columns) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const getData = async (e, data) => {
    const keys = [];
    const pendingSalesOrderResponse = await getPendingSalesOrders(bUnitId, documentTypeId, customerId);
    for (let i = 0; i < pendingSalesOrderResponse.length; i++) {
      pendingSalesOrderResponse[i].Customer_name = pendingSalesOrderResponse[i].customer.name;
      pendingSalesOrderResponse[i].product = pendingSalesOrderResponse[i].salesOrderLines.product.name;
      pendingSalesOrderResponse[i].orderQty = pendingSalesOrderResponse[i].salesOrderLines.qty;
      pendingSalesOrderResponse[i].stockQty = pendingSalesOrderResponse[i].salesOrderLines.stockqty;
      pendingSalesOrderResponse[i].reqQty = pendingSalesOrderResponse[i].salesOrderLines.requiredQty;
      pendingSalesOrderResponse[i].SoDate = pendingSalesOrderResponse[i].dateordered;
      pendingSalesOrderResponse[i].SalesOrder = pendingSalesOrderResponse[i].documentno;
      pendingSalesOrderResponse[i].ScheduledDeliveryDate = pendingSalesOrderResponse[i].datepromised;
      pendingSalesOrderResponse[i].key = pendingSalesOrderResponse[i].salesOrderLines.sOrderlineID;
      pendingSalesOrderResponse[i].Description = pendingSalesOrderResponse[i].salesOrderLines.description;
      pendingSalesOrderResponse[i].SalesRep = pendingSalesOrderResponse[i].salesRep.name;
      pendingSalesOrderResponse[i].imageUrl = pendingSalesOrderResponse[i].salesOrderLines.product.imageurl;
      pendingSalesOrderResponse[i].mProductId = pendingSalesOrderResponse[i].salesOrderLines.product.mProductId;
      pendingSalesOrderResponse[i].resReqQty = pendingSalesOrderResponse[i].salesOrderLines.reservedQty;
      pendingSalesOrderResponse[i].isReserved = pendingSalesOrderResponse[i].salesOrderLines.isReserved;

      const sordersubproducts = pendingSalesOrderResponse[i].sordersubproducts;
      // soOrdderId userId
      if (sordersubproducts.length > 0) {
        keys.push(pendingSalesOrderResponse[i].key);
      }
      const uniqueId = uuid().replace(/-/g, "").toUpperCase();

      for (let ind = 0; ind < sordersubproducts.length; ind++) {
        sordersubproducts[ind].mProductId = sordersubproducts[ind].product.mProductId;
        sordersubproducts[ind].imageUrl = sordersubproducts[ind].product.imageurl;
        sordersubproducts[ind].product = sordersubproducts[ind].product.name;
        sordersubproducts[ind].reqQty = sordersubproducts[ind].requiredQty;
        sordersubproducts[ind].orderQty = sordersubproducts[ind].qty;
        sordersubproducts[ind].Description = sordersubproducts[ind].product.description;
        sordersubproducts[ind].key = sordersubproducts[ind].sOrderLineAddonsId;
        sordersubproducts[ind].stockQty = sordersubproducts[ind].stockQty;
      }
      pendingSalesOrderResponse[i].children = pendingSalesOrderResponse[i].sordersubproducts;
    }

    setKeys(keys);
    setLoading(false);
    setDataSource(pendingSalesOrderResponse);
  };

  const onFinish = async (values) => {
    setLoading(true);
    // setColumn(finalColumns);
  };

  useEffect(() => {
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    SetUniueOrderId(uniqueId);
    getBusinessUnit();
  }, [changedValue]);

  const onSearch = () => {
    headerform.submit();
    setDataSource([]);
    getData();
  };

  const ExpandRow = (expanded, record) => {
    // console.log("record=======>", record);
    expanded = true;
    // console.log("expanded=======>", expanded);
    setExpand(true);
  };

  // console.log("priviewData=============>",priviewData)

  const onConfirm = () => {
    headerform.validateFields().then((values) => {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const userData = JSON.parse(window.localStorage.getItem("userData"));
      let newValueArray = []
      for (let index = 0; index < priviewData.length; index++) {
        const isReserved = priviewData[index].isReserved;
        if(isReserved === "Y"){
          newValueArray.push(priviewData[index].salesOrderLines.product.value)
        }
      }
      if(newValueArray.length > 0){
        Modal.error({
          title: 'Can not proceed,Stock is already reserved!',
          content: `${newValueArray}`,
          closable: true,
          footer: null,
          icon:null,
        })
      }else{
        if (priviewData.length > 0) {
          setLoading(true);
          const date = new Date();
          const date1 = moment(date).format("YYYY-MM-DD");
          let uniqueId = uuid().replace(/-/g, "").toUpperCase();
  
          const createPoOrder = {
            query: `mutation{
        createPurchaseRequisition(requistion:{
                purchaseRequistionId: "${uniueOrderId}" 
                  bUnit:{
                  cSBunitID:"${bUnitId}" 
                  }
                  requisitionType:{
                    typeId:"${documentTypeId}" 
                  }
                requisitionDate:"${date1}"
                requestedBy: "${date1}"
                description:null
                created: "${date1}"
                sOrderId: null
                sCustomerId:null
                requester:{
                requestedId:"${userData.user_id}"
                }
              createdBy:{
                userId:"${userData.user_id}"
                }
        })
         {
             status
             message
             id
             documentNo
         }
        }`,
          };
          Axios({
            url: serverUrl,
            method: "POST",
            data: createPoOrder,
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${newToken.access_token}`,
            },
          }).then((response) => {
            if (response.data.data !== null) {
              const Status = response.data.data.createPurchaseRequisition.status;
              const messageForSuccess = response.data.data.createPurchaseRequisition.message;
              if (Status === "200") {
                setLoading(false);
                // message.success(messageForSuccess)
                const recordId = response.data.data.createPurchaseRequisition.id;
                headerform.resetFields();
                SetUniueOrderId(uniqueId);
                const arrayForMutation = [];
                for (let index = 0; index < priviewData.length; index++) {
                  let uniqueId = uuid().replace(/-/g, "").toUpperCase();
                  arrayForMutation.push(
                    `{
          prequestlineId:"${uniqueId}"
          requisition:{
              purchaseRequistionId:"${recordId}"
          }
           product:{
               mProductId: ${priviewData[index].mProductId === null ? null : `"${priviewData[index].mProductId}"`}
           }
           description:null
             bUnit: {
              cSBunitID:"${bUnitId}" 
            }
           createdBy:{
              userId:"${userData.user_id}"
           }
            requiredQty: "${priviewData[index].reqQty}"
            estimatedPrice :"0"
             estimatedtotal :"0"
              created: "${date1}"
              sOrderLineId:"${priviewData[index].salesOrderLines.sOrderlineID}"
              sOrderQty:"${priviewData[index].salesOrderLines.qty}"
         }`
                  );
                }
  
                const createPoOrder2 = {
                  query: `mutation {
            createPRLines(pRLines: {
            pRLiness: [${arrayForMutation}]
            })
            {   
                status
                message
                recordsId
            }
            }`,
                };
                Axios({
                  url: serverUrl,
                  method: "POST",
                  data: createPoOrder2,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${newToken.access_token}`,
                  },
                }).then((response) => {
                  if (response.data.data.createPRLines.status === "200") {
                    message.success(response.data.data.createPRLines.message);
                    setDataSource([]);
                    setPriviewData([]);
                    setLoading(false);
                  } else {
                    message.success(response.data.data.createPRLines.message);
                    setLoading(false);
                  }
                });
              } else {
                message.error(messageForSuccess);
                setLoading(false);
              }
            } else {
              message.error("getting error while creating lines");
              setLoading(false);
            }
          });
        } else {
          message.error("Please select products to proceed!");
        }
      }
    });
  };

  const onSelectProductData = (e, data) => {
    setPriviewData(data);
  };
  const rowSelectionForProducts = {
    onChange: onSelectProductData,
  };
  const createReservation = () => {
    headerform.validateFields().then((values) => {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const userData = JSON.parse(window.localStorage.getItem("userData"));
      let sOrderID = [];
      for (let i = 0; i < priviewData.length; i++) {
        if (priviewData[i].isReserved !== "Y") {
          if (priviewData.length > 0) {
            setLoading(true);
            for (let index = 0; index < priviewData.length; index++) {
              let data = `"${priviewData[index].sOrderID}"`;
              sOrderID.push(data);
            }
            const createSoResrvation = {
              query: `mutation{
                 createSoReservation(userId:"${userData.user_id}", orderIds:[${sOrderID}]) 
                { 
                status
                message
                 }
                }
                
                `,
            };
            Axios({
              url: serverUrl,
              method: "POST",
              data: createSoResrvation,
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${newToken.access_token}`,
              },
            }).then((response) => {
              if (response.data.data.createSoReservation.status === "200") {
                message.success(response.data.data.createSoReservation.message);
                setLoading(false);
              } else {
                message.success(response.data.data.createSoReservation.message);
                setLoading(false);
              }
            });
          } else {
            message.error("Please select products to proceed!");
          }
        } else {
          message.error("Stock is already reserved!");
        }
      }
    });
  };

  const menu = (
    <Col style={{ height: "auto" }}>
      <Row>
        <Menu>
          <Menu.Item key="0" onClick={onConfirm} style={{ fontSize: "12px", fontWeight: "400" }}>
            Create Requisition
          </Menu.Item>
          <Menu.Item key="1" onClick={createReservation} style={{ fontSize: "12px", fontWeight: "400" }}>
            Create Reservation
          </Menu.Item>
        </Menu>
      </Row>
    </Col>
  );

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Pending Sales Order</h2>
          </Col>
          <Col span={12}>
            <span style={{ float: "right" }}>
              <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }} onClick={onSearch}>
                View
              </Button>
              <Dropdown trigger={["click"]} overlay={menu}>
                <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "100px", height: "33px" }}>
                  Actions&nbsp;&nbsp;
                  <img style={{ width: "12px" }} src={DownArrow} alt="DownArrow" />
                </Button>
              </Dropdown>
              {/* <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "150px", height: "33px" }} onClick={onConfirm}>
                Create Requisition
              </Button> */}
            </span>
          </Col>
        </Row>
        <Card style={{ marginBottom: "8px" }}>
          <Form layout="vertical" form={headerform} onFinish={onFinish}>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  name="businessUnit"
                  label="Business unit"
                  style={{ marginBottom: "8px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select business bunit!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getBusinessUnit}
                    onSelect={onSelectBusinessUnit}
                  >
                    {bunitData.map((data, index) => (
                      <Option key={data.csBunitId} value={data.csBunitId} title={data.bUnitName}>
                        {data.bUnitName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  name="documentType"
                  label="Document Type"
                  style={{ marginBottom: "8px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please select document type!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getDocument}
                    onSelect={onSelectDocumentType}
                  >
                    {documentData.map((data) => (
                      <Option key={data.RecordID}>{data.Name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item name="customer" label="Customer" style={{ marginBottom: "8px" }}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getCustomer}
                    onChange={onSelectCustomer}
                  >
                    {customerData.map((data) => (
                      <Option key={data.RecordID}>{data.Name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Table
          columns={finalColumns}
          dataSource={dataSource}
          components={components}
          size="small"
          onExpand={ExpandRow}
          expandedRowKeys={expand ? "" : keys}
          rowSelection={{
            ...rowSelectionForProducts,
          }}
          scroll={{ y: "64.5vh", x: "100%" }}
          pagination={false}
        />
      </div>
    </Spin>
  );
};

export default PendingSalesOrder;
