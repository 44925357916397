import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Select, Card, Col, Row, Spin, DatePicker, message, Upload, Button } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { getProductCategoryAttribute, getDesignDetails, getDesign } from "../../../services/custom";
import "antd/dist/antd.css";
import "../../../styles/antd.css";
import Axios from "axios";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router";

import moment from "moment";
import { serverUrl } from "../../../constants/serverConfig";
import DesignDetails from "../NewProductDesign/designDetails";

import { useParams } from "react-router-dom";
import { getProductCategoryData, getNewProductData, getSubProductData, getDesignedByData, getSketchRefData } from "../../../services/generic";

const NewProductDesign = () => {
  const { Option } = Select;
  const [headerForm] = Form.useForm();
  const [form] = Form.useForm();

  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState([]);
  const [designCode, setDesignCode] = useState("");
  const [designName, setDesignName] = useState("");
  // const [designDetails, setDesignDetails] = useState([]);
  const [bUnitId, setBUnitId] = useState("");
  const [productId, setProductId] = useState("");
  const [subProductId, setSubProductId] = useState(null);
  const { recordId } = useParams();
  const { NewRecordName } = useParams();
  const [sketchRef, setSketchRef] = useState(null);
  const [designedById, setDesignedById] = useState(null);
  const [designId, setDesignId] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [designDetail, setDesignDetails] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState("");
  const [imageVisible, setImageVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  // const [isKey, setIsKey] = useState("");

  // form states start
  const [bunitData, setBunitData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [subProductData, setSubProductData] = useState([]);
  const [designedByData, setDesignedByData] = useState([]);
  const [sketchData, setSkecthData] = useState([]);
  const [isValue, setIsValue] = useState(false);
  // form states end

  const changePage = () => {
    history.push(`/others/window/7447`);
  };

  useEffect(() => {
    if (recordId === "New_Record") {
      setIsValue(true);
      form.resetFields();
      headerForm.resetFields();
      setFormVisible(false);
      setDesignName("");
      setDesignCode("");
      setIsTableVisible(true);
    } else if (recordId) {
      setIsTableVisible(true);
      setLoading(true);
      getData(recordId);
    } else {
      setIsTableVisible(false);
      getDesignData("");
    }
  }, [recordId]);
  const history = useHistory();

  const passData = (data) => {
    console.log(data)
    if(data.length>0){
      getDesignData(data);
    }
  };

  // service Calls start
  const getBunitId = async () => {
    const productCategory = await getProductCategoryData();
    setBunitData(productCategory.searchData);
  };

  const getProduct = async () => {
    const productResponse = await getNewProductData();
    setProductData(productResponse.searchData);
  };
  const getSubProduct = async () => {
    const subProductResponse = await getSubProductData();
    setSubProductData(subProductResponse.searchData);
  };

  const getDesignedBy = async () => {
    const designedByRes = await getDesignedByData();
    setDesignedByData(designedByRes.searchData);
  };

  const getSkecthRef = async () => {
    const skecthRes = await getSketchRefData();
    setSkecthData(skecthRes.searchData);
  };
  const getDesignData = async (data) => {
    const designDataRes = await getDesign(data,0,100);
    designDataRes.forEach((item) => {
      item.key = item.plmDesignId;
    });
    setDesignDetails(designDataRes);
    // console.log(designDataRes);
  };

  const getData = async (recordId) => {
    const productResponse = await getDesignDetails(recordId);
    setLoading(false);
    console.log(productResponse);
    form.setFieldsValue({
      productCategory: productResponse[0].categoryName,
      designName: productResponse[0].designName,
      designCode: productResponse[0].designcode,
      product: productResponse[0]?.productName,
      subProduct: productResponse[0].subProductName,
      designedBy: productResponse[0].disignerName,
      // designImage: productResponse[0].image,
      sketchReference: productResponse[0].sketchName,
      designDescription: productResponse[0].description,
    });
    if (productResponse[0].image !== "undefined") {
      setImageVisible(true);
      setImgSrc(productResponse[0].image);
    }else{
      setImageVisible(false);

      setImgSrc('')
    }

    setProductCategoryId(productResponse[0].productCategory);
    setSketchRef(productResponse[0].sketchId);
    setDesignedById(productResponse[0].designedBy);
    setSubProductId(productResponse[0].subProduct);
    setDesignId(productResponse[0].plmDesignId);
    setDesignCode(productResponse[0].designcode);
    setDesignName(productResponse[0].designName);
    setBUnitId(productResponse[0].bunitId);
    setProductId(productResponse[0].product);
    if (productResponse[0].attributes.length > 0) {
      FormValuesData(productResponse[0].attributes);
    }
  };

  // service Calls end

  const FormValuesData = (FormData) => {
    if (FormData.length > 0) {
      for (let i = 0; i < FormData?.length; i++) {
        if (FormData[i].referenceValue?.includes(",")) {
          FormData[i].varient = "Y";
          FormData[i].data = FormData[i].referenceValue.split(",");
          FormData[i].data1 = FormData[i].value.split(",");
        } else {
          FormData[i].varient = "Y";
          FormData[i].data = [];
          let data = FormData[i].referenceValue ? FormData[i].referenceValue : null;
          FormData[i].data.push(data);
          FormData[i].data1 = [];
          let data1 = FormData[i].value ? FormData[i].value : null;
          FormData[i].data1.push(data1);
        }
      }

      setFormData(FormData);
      setFormVisible(true);
    } else {
      setFormData([]);
      setFormVisible(false);
    }
  };

  const getFormData = async (e) => {
    // headerForm.resetFields();
    const FormData = await getProductCategoryAttribute(e);

    FormData.forEach((element) => {
      if (element.value) {
        element.referenceValue = element.value;
        // element.SelectorValue = element.value;
      }
    });
    FormValuesData(FormData);

    console.log(FormData);
  };

  const onChange = async (e) => {
    const subProductResponse = await getSubProductData(e);
    setSubProductData(subProductResponse.searchData);
  };

  const imageUploadStatusChange = (uploadStatus) => {
    const fieldsToUpdate = {};
    fieldsToUpdate["item"] = uploadStatus.file.response;
    console.log(fieldsToUpdate);
    form.setFieldsValue({
      designImage: uploadStatus.file.response,
    });
  };

  // getting form field's id's start
  const getBusUnitId = (e) => {
    // setBUnitId(e);
    setProductCategoryId(e);
  };

  const getProductId = (e) => {
    setProductId(e);
  };

  const getsubProcuctId = (e) => {
    getFormData(e);
    setSubProductId(e);
  };
  const getDesignedById = (e) => {
    setDesignedById(e);
  };
  const getSketchRef = (e) => {
    setSketchRef(e);
  };

  const getDesignCode = (e) => {
    setDesignCode(e.target.value);
  };

  const getDesignName = (e) => {
    setDesignName(e.target.value);
  };

  // getting form field id's end

  const onChangeData =(data)=>{
setDesignDetails(data)
  }

  const onClickNew = ()=>{
    setImageVisible(false)
    console.log('click')
  }

  // upsert services start
  const createDesignDetails = () => {
    const headerFormValues = headerForm.getFieldsValue(true);

    form.validateFields().then((values) => {
      const formValues = form.getFieldsValue(true);
      console.log(formValues);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      let uniqueId = uuid().replace(/-/g, "").toUpperCase();
      if (formData.length > 0) {
        setLoading(true);
        const arrayForMutation = [];
        for (let i = 0; i < formData.length; i++) {
          let uniqueId1 = uuid().replace(/-/g, "").toUpperCase();
          Object.entries(headerFormValues).map((item) => {
            if (formData[i].designAttributeId === item[0]) {
              // console.log(formData[i].lineAttributeId);
              arrayForMutation.push(`{
                designAttributeId : "${formData[i].designAttributeId}",
                lineAttributeId: "${formData[i].lineAttributeId ? formData[i].lineAttributeId : uniqueId1}",
                varient: "${formData[i].varient}",
                value: "${item[1]}"
              }`);
            }
          });
        }

        const create = {
          query: `mutation {
            upsertDesign(design: {
              productCategory:"${productCategoryId}"
               plmDesignId: "${designId ? designId : uniqueId}"
             designcode: "${formValues.designCode}"
             designName: "${formValues.designName}" 
             bunitId: "${bUnitId ? bUnitId : 0}"
             image: "${formValues?.designImage}"
             description: ${JSON.stringify(formValues.designDescription)}
             designedBy: ${designedById ? `"${designedById}"` : null}
             sketchId:  ${sketchRef ? `"${sketchRef}"` : null}
             subProduct: ${subProductId ? `"${subProductId}"` : null}
             product: ${productId ? `"${productId}"` : null}
             attributes: [${arrayForMutation}]
          
             }) { 
          status
          message   
          }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: create,
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${newToken.access_token}`,
          },
        }).then((response) => {
          console.log(response.data.data.upsertDesign.status);
          if (response.data.data.upsertDesign.status === "200") {
            message.success(response.data.data.upsertDesign.message);
            setLoading(false);
            form.resetFields();
            headerForm.resetFields();
            setFormVisible(false);
            setDesignName("");
            setDesignCode("");
            history.push(`/others/window/7447/New_Record`);
          } else {
            setLoading(false);

            message.error("getting error while creating lines");
          }
        });
      } else {
        message.error("select product");
      }
    });
  };

  // upsert services end

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      {isTableVisible ? (
        <>
          <Row>
            <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
              <span
                onClick={changePage}
                style={{
                  fontSize: "11px",
                  paddingLeft: "0px",
                  color: "rgb(22, 72, 170)",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                  fontWeight: "500",
                  fontFamily: "Open sans",
                  cursor: "pointer",
                }}
              >
                Product Design
              </span>

              <span style={{ fontSize: "22px", font: "normal normal 600 22px/30px  Open Sans", color: "#666666" }}>
                {designCode} {designName.length !== 0 ? ` - ${designName}` : ""}
              </span>
            </Col>
            <Col span={12}>
              <span style={{ float: "right" }}>
                <Button onClick={createDesignDetails} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
                  Save
                </Button>
              </span>
            </Col>
          </Row>
          <Card style={{ marginBottom: "8px" }}>
            <Form layout="vertical" form={form}>
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    label="Product Category"
                    name="productCategory"
                    rules={[
                      {
                        required: true,
                        message: "Please select Product Category!",
                      },
                    ]}
                    style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
                  >
                    <Select
                      // defaultValue="*"
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getBunitId}
                      onSelect={getBusUnitId}
                    >
                      {bunitData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Product" name="product" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select
                      onSelect={getProductId}
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getProduct}
                    >
                      {productData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Sub Product" name="subProduct" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getSubProduct}
                      onSelect={getsubProcuctId}
                      onSearch={onChange}
                    >
                      {subProductData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <p />
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    label="Design Code"
                    name="designCode"
                    rules={[
                      {
                        required: true,
                        message: "Please select Design Code!",
                      },
                    ]}
                    style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
                  >
                    <Input onChange={getDesignCode}></Input>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select Design Name!",
                      },
                    ]}
                    label="Design Name"
                    name="designName"
                    style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
                  >
                    <Input onChange={getDesignName}></Input>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Designed By" name="designedBy" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select
                      onSelect={getDesignedById}
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getDesignedBy}
                    >
                      {designedByData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <p />

              <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    label="Design Description"
                    name="designDescription"
                    initialValue={description}
                    rules={[
                      {
                        required: true,
                        message: "Please enter description",
                      },
                    ]}
                    style={{ marginBottom: "8px" }}
                  >
                    <ReactQuill
                      theme="snow"
                      // onChange={handleChange}
                      // value={description}
                      modules={{
                        toolbar: [
                          [{ font: [false, "serif", "monospace"] }, { header: [1, 2, 3, 4, 5, 6, false] }],
                          ["bold", "italic", "underline", "strike", "blockquote"],
                          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                          [{ align: [] }],
                          ["code", "background"],
                          ["code-block", "direction"],
                          ["link", "image", "video"],
                          ["clean"],
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <p />
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Sketch Reference" name="sketchReference" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select onFocus={getSkecthRef} onSelect={getSketchRef}>
                      {sketchData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  {imageVisible ? (
                    <img style={{ height: 90, width: 90 }} src={imgSrc} />
                  ) : (
                    <Form.Item label="Design Image" name="designImage" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                      <Upload
                        action="https://sapp.mycw.in/image-manager/uploadImage"
                        listType="picture"
                        headers={{ APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg" }}
                        name="image"
                        onChange={imageUploadStatusChange}
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Form.Item>
                  )}
                </Col>
                <Col className="gutter-row" span={8} />
              </Row>
            </Form>
          </Card>
          {formVisible ? (
            <Card>
              <Form layout="vertical" form={headerForm}>
                <Row gutter={16}>
                  {formData.map((element) => {
                    {
                      switch (element.type) {
                        case "ST":
                          return (
                            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                              <Form.Item label={element.name} name={element.designAttributeId} initialValue={element.value}>
                                <Input />
                              </Form.Item>
                            </Col>
                          );
                        case "LI":
                          return (
                            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                              <Form.Item initialValue={isValue ? element.data : element.data1} label={element.name} name={element.designAttributeId} props={element}>
                                <Select mode="multiple" maxTagCount="responsive" showSearch style={{ width: "100%", marginBottom: "0px" }}>
                                  {element?.data.map((option, index) => (
                                    <Option key={`${index}-${element.designAttributeId}`} value={option}>
                                      {option}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          );
                        case "DA":
                          return (
                            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                              <Form.Item initialValue={moment(element.value)} label={element.name} name={element.designAttributeId}>
                                <DatePicker format={"DD-MM-YYYY"} />
                              </Form.Item>
                            </Col>
                          );
                      }
                    }
                  })}
                </Row>
              </Form>
            </Card>
          ) : (
            ""
          )}
          
        </>
      ) : (
        <DesignDetails designDetail={designDetail} passData={passData} onChangeData={onChangeData} onClickNew = {onClickNew}/>
      )}
    </Spin>
  );
};
export default NewProductDesign;
